import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'components/App';
import MainPage from 'pages/MainPage/MainPage';

import './index.css';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    {/* <BrowserRouter basename="Drawing"> */}
    {/* <App /> */}
    <MainPage />
    {/* </BrowserRouter> */}
  </React.StrictMode>
);
