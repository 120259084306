import React from 'react';
import Header from 'components/Header/Header';
import Main from 'components/Main/Main';
import Footer from 'components/Footer/Footer';

const MainPage = () => {
  return (
    <>
      {/* <Header /> */}
      <Main />
      <Footer />
    </>
  );
};

export default MainPage;
